// Import Paper.js
import paper from 'paper'
// Import styles
import './styles.css'

function createCanvasElements() {
  // Clear existing elements if any
  paper.project.activeLayer.removeChildren()
  // Create radial gradient background
  var background = new paper.Path.Rectangle(paper.view.bounds)
  background.fillColor = {
    gradient: {
      stops: ['#5C3E33', '#C7915F', '#5C3E33'], // Blue-ish color scheme
      radial: false,
    },
    origin: paper.view.topLeft,
    destination: background.bounds.rightCenter,
  }

  // Function to create a moving element with radial gradient
  function createMovingElement() {
    var element = new paper.Path.Rectangle(paper.view.bounds)
    element.fillColor = {
      gradient: {
        stops: ['rgba(153, 82, 73, 1)', 'rgba(234, 82, 80, 0)'], // Blue-ish color scheme
        radial: true,
      },
      origin: paper.view.center,
      destination: element.bounds.rightCenter,
    }

    // Properties for animation
    element.vector = new paper.Point(Math.random() - 0.5, Math.random() - 0.5)
    element.vector.length = Math.random() * movementSpeed

    return element
  }

  // Create and return two moving elements
  return [createMovingElement(), createMovingElement()]
}

// Set up Paper.js on the canvas
paper.setup(document.getElementById('app'))

// Speed variable for the movement of gradients
const movementSpeed = 1.5

// Create initial elements
let [movingElement1, movingElement2] = createCanvasElements()

// Animation loop
paper.view.onFrame = function (event) {
  var elements = [movingElement1, movingElement2]
  elements.forEach(function (element) {
    element.fillColor.origin = element.fillColor.origin.add(element.vector)

    // Bounce off edges
    if (element.fillColor.origin.x < 0 || element.fillColor.origin.x > paper.view.size.width) {
      element.vector.x *= -1
    }
    if (element.fillColor.origin.y < 0 || element.fillColor.origin.y > paper.view.size.height) {
      element.vector.y *= -1
    }
  })
}

// Handle window resize
window.addEventListener('resize', function () {
  // Update Paper.js view size
  paper.view.viewSize = new paper.Size(window.innerWidth, window.innerHeight)
  // Recreate canvas elements
  ;[movingElement1, movingElement2] = createCanvasElements()
})
